import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-hot-toast@2.4.1_csstype@3.1.3_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-hot-toast/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/src/app/[lang]/globalicons.css");
import(/* webpackMode: "eager" */ "/app/src/app/[lang]/globals.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.0.4_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"Kanit\",\"arguments\":[{\"subsets\":[\"thai\",\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"variable\":\"--font1\"}],\"variableName\":\"kanit\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.0.4_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"IBM_Plex_Sans_Thai_Looped\",\"arguments\":[{\"subsets\":[\"thai\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font2\"}],\"variableName\":\"ibm\"}");
import(/* webpackMode: "eager" */ "/app/src/app/[lang]/providers.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/[lang]/StoreProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ui/share-social-modal.tsx");
import(/* webpackMode: "eager" */ "/app/src/lib/providers/cart-provider.tsx");
import(/* webpackMode: "eager" */ "/app/src/lib/providers/profile-provider.tsx");
import(/* webpackMode: "eager" */ "/app/src/lib/providers/system-config-provider.tsx")